* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: slategray;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
