/* #f9f9ff */

.section--GDPR {
    padding-top: 2rem;
    padding-right: 1rem;
    background-color: #f9f9ff;
}

.ul_moje {
    padding-left: 2rem;
    margin-bottom: 1rem;
}

.gdpr-title-line-height {
    line-height: 1.5;
    font-size: 2rem;
}