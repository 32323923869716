:root {
  --color-text: #777777;
  --color-background: #f9f9ff;
  --color-primary: #dc7000;
  --color-primary-hover: #6babab;
  --color-secondary: #bacaea;
  --color-black: #000000;
  --color-white: #ffffff;
  
  --font-family-primary: 'AllroundGothic', sans-serif;
  --font-family-secondary: 'Blogger Sans', 'Avenir', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  --font-family-tertiary: 'Arbutus Slab', 'Blogger Sans', 'Avenir', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  --font-family-form: 'Lexend', sans-serif;
  
  --font-size-small: 14px;
  --font-size-medium: 1.125rem;
  --font-size-large: 1.5rem;
  --font-size-xlarge: 2rem;
  --font-size-xxlarge: 2.75rem;
  --font-size-title: 3rem;
  --font-size-heading: 5rem;

  --line-height: 1.625em;
  --line-height-title: 1.3;
}

body {
  color: var(--color-text);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  font-weight: 300;
  line-height: var(--line-height);
  margin: 0;
}

@keyframes slide-wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1440px);
  }
}

.moving {
  animation: slide-wave 10s linear infinite;
}

.app {
  z-index: 1;
  position: relative;
}

.header {
  background: var(--color-secondary);
  transition: background-color 0.3s ease;
}

.header.scrolled {
  background: var(--color-background);
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.1);
}

.header_wraper {
  display: flex;
  justify-content: space-between;
  max-width: 1320px;
  align-items: center;
  width: 100%;
}

.header_wraper_auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-header .logo img {
  width: 150px;
  transition: all 0.3s ease;
}

.nav-link {
  color: var(--color-white) !important;
  transition: color 0.3s ease;
  font-family: var(--font-family-secondary);
  font-size: 1.2rem;
  font-weight: 700;
}

.nav-scrolled {
  color: var(--color-black);
  transition: color 0.3s ease;
  font-family: var(--font-family-secondary);
  font-size: 1.2rem;
}

.desktop-menu {
  display: none;
}

.mobile-menu {
  display: flex;
  align-items: center;
}

@media (min-width: 769px) {
  .desktop-menu {
    display: flex;
    align-items: center;
  }

  .mobile-menu {
    display: none;
  }
}

.main-section {
  max-width: 1320px;
  margin: 0 auto;
}

.main-section-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
  max-width: 1320px;
}

.main-section__image {
  flex: 0 0 35%;
  height: auto;
  padding: 0;
  border-radius: 30px 50px 50px 30px;
  max-width: 100%;
}

.main-section__text {
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-section__text-inner {
  margin: 0;
}

.main-section__title {
  font-weight: 800;
  font-size: var(--font-size-heading);
  color: var(--color-white);
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: var(--line-height-title);
  font-family: var(--font-family-secondary);
}

.main-section__description {
  color: var(--color-white);
  font-size: var(--font-size-large);
  margin-top: 40px;
  font-family: var(--font-family-secondary);
}

.main-section__button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.main-section__button {
  text-decoration: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background: var(--color-primary);
  line-height: 59px;
  padding: 0 39px;
  font-size: var(--font-size-large);
  margin-bottom: 20px;
  border: none;
  border-radius: 20px;
  color: var(--color-white);
  display: inline-block;
  font-weight: 500;
  max-height: 68px;
  white-space: nowrap;
  font-family: var(--font-family-secondary);
}

.main-section__button:hover {
  background: var(--color-primary-hover);
  color: var(--color-white);
}

@media only screen and (max-width: 1020px) {
  .main-section-cover {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-section__image {
    display: none;
    flex: none;
    height: auto;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .main-section__text {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .main-section__title {
    font-size: 2.5rem;
    text-align: center;
  }

  .main-section__description {
    font-size: 1.25rem;
    text-align: center;
  }

  .main-section__text-inner {
    margin: 20px 10px;
  }

  .main-section__button-wrapper {
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .main-section-cover {
    height: auto;
  }

  .main-section__title {
    font-size: 2rem;
    line-height: 2.3rem;
  }

  .main-section__description {
    font-size: 1rem;
    text-align: center;
  }

  .main-section__text-inner {
    text-align: center;
  }

  .main-section__button-wrapper {
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }

  .main-section__button {
    line-height: 40px;
    padding: 0 20px;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .main-section__image {
    display: none;
  }
}

.section {
  padding-top: 48px;
  display: flex;
  justify-content: center;
  background-color: var(--color-background);
}

.section--features {
  padding-top: 80px;
}

.section__container {
  max-width: 1600px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.section__title {
  text-align: center;
  font-size: var(--font-size-title);
  margin-bottom: 2rem;
  font-family: var(--font-family-tertiary);
  color: #424242;
}

.section__description {
  font-size: var(--font-size-medium);
  text-align: center;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--font-family-secondary);
  color: #797979;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  text-align: center;
  justify-content: center;
}

.features__item {
  padding: 20px;
}

.features__icon {
  font-size: 40px;
  color: #222;
  margin-bottom: 16px;
}

.features__title {
  font-size: var(--font-size-large);
  color:  var(--color-text);
  margin-bottom: 8px;
  font-weight: 550;
  font-family: var(--font-family-secondary);
}

.features__description {
  font-size: 14.5px;
  font-weight: 450;
  line-height: var(--line-height);
  color: var(--color-text);
}

@media (max-width: 1024px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .features__item {
    padding: 10px;
  }

  .features__title {
    font-size: 1.5rem;
  }

  .features__description {
    font-size: 14px;
  }
}

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
}

.card {
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  text-align: center;
  padding: 2rem;
}

.card-body {
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: var(--font-size-xlarge);
  color: var(--color-black);
  text-align: left;
}

.title2line {
  margin-top: 10px;
}

.description {
  font-size: var(--font-size-medium);
  margin: 1.5rem 0;
  color: var(--color-black);
  text-align: left;
}

.contact-info {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  flex-direction: column;
}

.contact-row {
  color: var(--color-black);
  font-size: 1rem;
  text-align: left;
}

.contact-form {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 53%;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group label {
  color: #333;
}

.form-group input,
.form-group textarea {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0 10px;
  min-height: 50px;
  font-family: var(--font-family-form);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: var(--color-text);
  background: var(--color-white);
}

.half-width {
  width: 48%;
}

.checkbox-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.checkbox-group input {
  margin-right: 0.5rem;
}

.submit-button-wrapper {
  display: flex;
  justify-content: center;
}

.submit-button {
  text-decoration: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background: var(--color-primary-hover);
  line-height: 44px!important;
  padding: 0px 16px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  color: var(--color-white);
  display: inline-block;
  font-weight: 500;
  max-height: 68px;
  white-space: nowrap;
  font-family: var(--font-family-secondary);
  height: 100%!important;
}

.submit-button:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
  .description,
  .contact-row,
  .title {
    text-align: center;
  }
  .contact-form {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .contact-row {
    margin-bottom: 0.5rem;
  }

  .card {
    padding: 1rem;
  }

  .card-body {
    padding: 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .form-row {
    flex-direction: column;
  }

  .half-width {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .ant-layout-header {
    padding: 0 20px !important;
  }
}
