/* #f9f9ff */

.section--reference {
    padding-top: 2rem;
    padding-right: 1rem;
    background-color: #f9f9ff;
}

.rounded-5rem {
    border-radius: .5rem ;
}

.reference {
    --si-gutter-x: 1.5rem;
    --si-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--si-gutter-x)*-.5);
    margin-right: calc(var(--si-gutter-x)*-.5);
    margin-top: calc(var(--si-gutter-y)*-1);
    padding-bottom: 3rem;
    /* margin-bottom: 1.5rem; */
    margin-bottom: 3rem;
}

.reference__picture_l {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: .25rem;
    /* margin-bottom: 1rem; */
    margin-bottom: 0;
    padding-bottom: 0;
}

.reference__picture_r {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: .25rem;
    /* margin-bottom: 1rem; */
    margin-bottom: 0;
    padding-bottom: 0;
    order: 2;
}

.reference__text {
    flex: 0 0 auto;
    width: 50%;
    order: 1;
}

.reference__item {
    padding-left: 1.5rem;
    margin-left: .5rem;
}
.reference__item_2{
    padding-left: 0px;
    margin-left: 0px;
}
.reference__badge {
    --si-badge-padding-x: .6em;
    --si-badge-padding-y: .35em;
    --si-badge-font-weight: 600;
    --si-badge-border-radius: .25rem;
    border-radius: var(--si-badge-border-radius,0);
    display: inline-block;
    font-weight: var(--si-badge-font-weight);
    line-height: 1;
    padding: var(--si-badge-padding-y) var(--si-badge-padding-x);
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: #eef3ff;
    color: #6585b2;
    font-size: .875rem;
    margin-bottom: 1rem;
    margin-right: .5rem;
}

.reference__description {
    /* display: none; */
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.h1, .h2, .h3, .h4, .h5, .h6{
    color: #000000;
    font-weight: 800;
    line-height: 1.3;
    margin-bottom: 1rem;
    margin-top: 0;
}

.h3, h3 {
    font-size: 1.75rem;
}


.btn-outline-primary {
    text-decoration: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    background: #6babab;
    line-height: 44px;
    padding: 0px 16px;
    font-size: 1rem;
    margin-bottom: 20px;
    border: none;
    border-radius:10px;
    color: #ffffff;
    display: inline-block;
    font-weight: 500;
    max-height: 68px;
    white-space: nowrap;
    font-family: 'Avenir', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.btn-outline-primary:hover{
    background: #dc7000;
    color: #fff;
}

.padding {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (max-width:1020px) {
    .reference__description{
        display: none;
    }
}

@media (max-width:768px) {
    .reference__item {
        margin-top: 15px;
         padding-left: 0px;
    }
    .reference{
        flex-direction: column;
         margin-bottom: 1.5rem;
         padding-bottom: 0px;
    }
    .reference_2{
        flex-direction: column-reverse; 
    }
    .reference__description{
        display: block;
    }
    .reference__picture_r{
        width: 100%;
    }
    .reference__picture_l{
        width: 100%;
    }
    .reference__text{
        width: 100%;
    }
    .section--reference {
        padding-right: 0px; 
    }
}

/* 
.row {
    --si-gutter-x: 1.5rem;
    --si-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--si-gutter-x)*-.5);
    margin-right: calc(var(--si-gutter-x)*-.5);
    margin-top: calc(var(--si-gutter-y)*-1);
}

.pb-5 {
    padding-bottom: 3rem;
}

.mb-md-4 {
    margin-bottom: 1.5rem;
}

.mb-lg-5 {
    margin-bottom: 3rem;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.mb-3 {
    margin-bottom: 1rem;
}

.pb-1 {
    padding-bottom: .25rem;
}

.mb-md-0 {
    margin-bottom: 0;
}

.pb-md-0 {
    padding-bottom: 0;
}

.order-md-1 {
    order: 1;
}

.ms-md-2 {
    margin-left: .5rem;
}

.ps-md-4 {
    padding-left: 1.5rem;
}

.badge {
    --si-badge-padding-x: .6em;
    --si-badge-padding-y: .35em;
    --si-badge-font-size: .8125em;
    --si-badge-font-weight: 600;
    --si-badge-color: #fff;
    --si-badge-border-radius: .25rem;
    border-radius: var(--si-badge-border-radius,0);
    color: var(--si-badge-color);
    display: inline-block;
    font-size: var(--si-badge-font-size);
    font-weight: var(--si-badge-font-weight);
    line-height: 1;
    padding: var(--si-badge-padding-y) var(--si-badge-padding-x);
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.d-inline-flex {
    display: inline-flex;
}

.me-2 {
    margin-right: .5rem;
}

.fs-sm {
    font-size: .875rem;
}

.text-primary {
    color: #ff8303;
}

.bg-faded-primary {
    background-color: #ff83031f;
}

.d-md-none {
    display: none;
}

.mb-md-3 {
    margin-bottom: 1rem;
}

.d-lg-block {
    display: block;
}

*/