@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: 'Blogger Sans';
  src: url('./fonts/new/Blogger_Sans.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('./fonts/new/Blogger_Sans-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('./fonts/new/Blogger_Sans-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('./fonts/new/Blogger_Sans-Bold_Italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'AutourOne'; 
  src: url('./fonts/new/AutourOne-Regular.otf') format('opentype'); 
  font-weight: normal; 
  font-style: normal;
}
@font-face {
  font-family: 'KaushanScript'; 
  src: url('./fonts/new/KaushanScript-Regular.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Arbutus Slab'; 
  src: url('./fonts/arbutusslab-regular.ttf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir';
  src: url("./fonts/2020/Avenir-Light.woff2") format('woff2'),
      url('./fonts/2020/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/2020/Avenir-Roman.woff2') format('woff2'),
      url('./fonts/2020/Avenir-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/2020/Avenir-Medium.woff2') format('woff2'),
      url('./fonts/2020/Avenir-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/2020/Avenir-Heavy.woff2') format('woff2'),
      url('./fonts/2020/Avenir-Heavy.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/2020/Avenir-Black.woff2') format('woff2'),
      url('./fonts/2020/Avenir-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}



.mainContent {
  margin: 20px 50px 0px 50px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-space-gap-row-large {
  row-gap: 1px!important;
}
