.section--projects {
  padding-top: 3rem;
  padding-right: 1rem;
  background-color: #f9f9ff;
  margin-bottom: 3rem;
}



.projects {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 2rem;
  margin-bottom: 2rem;
}

.projects2 {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 2rem;
}

.projects__item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.1);
}

.projects__item--small {
  grid-column: span 1;
  grid-row: span 1;
  max-height: 450px;
}

.projects__item--large {
  grid-column: span 1;
  grid-row: span 1;
  max-height: 450px;
}

.projects__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.projects__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.projects__title,
.projects__description {
  color: #fff;
  margin: 0;
}

.projects__title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.projects__description {
  font-size: 1rem;
}

.projects__item:hover .projects__img {
  transform: scale(1.1);
}

.projects__item:hover .projects__overlay {
  opacity: 1;
}

.section__footer {
  text-align: center;
  margin-top: 2rem;
}

.section__button {
  text-decoration: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background: #6babab;
  line-height: 44px;
  padding: 0px 16px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: none;
  border-radius:10px;
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  max-height: 68px;
  white-space: nowrap;
  font-family: 'Avenir', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.section__button:hover {
  background: #dc7000;
  color: #fff;
}

@media (max-width: 768px) {
  .projects {
    grid-template-columns: 1fr;
  }

  .projects2 {
    grid-template-columns: 1fr;
  }

  .projects__item {
    height: auto;
  }

  .projects__item--small {
    max-height: 306px;
  }

  .projects__img {
    object-fit: contain;
  }

  .projects__overlay {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
  }

  .projects__img {
    transform: none;
  }

  .projects__title,
  .projects__description {
    opacity: 1;
  }
}