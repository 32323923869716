.footer {
  background: #bacaea;
  padding-top: 4rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
  z-index: 1;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.footer__col {
  flex: 1 1 100%;
  max-width: 33%;
  margin-bottom: 1rem;
}

.footer_description {
  font-size: 0.875rem;
  color: #000;
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
}

.footer__links-col {
  flex: 1 1 100%;
  max-width: 33%;
  margin-bottom: 1rem;
}

.footer__links-title {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.footer_buttons {
  list-style-type: none;
}

.footer_button {
  color: #000;
}
.footer_button:hover{
  color: #1677ff;
}

.concatinate {
  color: #000;
}

@media (max-width: 768px) {
  .footer__row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer__col,
  .footer__links-col {
    max-width: 100%;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
  }
}



.fs-xs {
  font-size: 0.75rem;
}

.fw-medium {
  font-weight: 500;
}